<template>
  <content-not-view v-if="!subPermission.index" />
  <Loading v-else-if="isLoading" />
  <div v-else class="users-list">
    <b-card>
      <div class="custom-search d-flex justify-content-between mb-1 options">
        <!-- Start Search Input -->
        <b-form-group>
          <div class="d-flex align-items-end">
            <div class="mr-2">
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('g.searchHere')"
                type="text"
                class="d-inline-block"
              />
            </div>
            <b-form-select
              v-model="filterQuery"
              :options="filterDropdownItems"
            />
          </div>
        </b-form-group>
        <!-- End Search Input -->
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="vgt-table striped condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.country/country_list/name") }}
          </span>
          <span v-else-if="props.column.label === 'lang'" class="text-nowrap">
            {{ $t("g.country/country_list/language") }}
          </span>
          <span v-else-if="props.column.label === 'code'" class="text-nowrap">
            {{ $t("g.country/country_list/code") }}
          </span>
          <span
            v-else-if="props.column.label === 'currency'"
            class="text-nowrap"
          >
            {{ $t("g.country/country_list/currency") }}
          </span>
          <span v-else-if="props.column.label === 'flag'" class="text-nowrap">
            {{ $t("g.country/country_list/flag") }}
          </span>
          <span
            v-else-if="props.column.label === 'is_available'"
            class="text-nowrap"
          >
            {{ $t("g.country/country_list/is_available") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'is_available' && subPermission.update"
            class="text-nowrap"
          >
            {{
              props.row && props.row.type === "guardian"
                ? "parent"
                : props.row.type
            }}
            <b-form-checkbox
              v-model="props.row.is_available"
              @change="updateCountry(props.row.id, props.row.is_available)"
              switch
            />
          </span>
          <span v-else-if="props.column.field === 'code'" class="text-nowrap">
            {{ `+${props.row.code}` }}
          </span>
          <span v-else-if="props.column.field === 'flag'" class="text-nowrap">
            <b-avatar
              :src="`https://flaglog.com/codes/standardized-rectangle-120px/${props.row.flag}.png`"
            >
            </b-avatar>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  BButton,
  BFormCheckbox,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    BDropdownItem,
    BDropdown,
    OverlayComponent,
    Loading,
    BFormCheckbox,
    contentNotView,
    BAvatar,
  },
  data() {
    return {
      isLoading: true,
      overlayLoading: false,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "lang",
          field: "lang",
        },
        {
          label: "code",
          field: "code",
        },
        {
          label: "currency",
          field: "currency",
        },
        {
          label: "flag",
          field: "flag",
        },
        {
          label: "is_available",
          field: "is_available",
        },
      ],
      filterDropdownItems: [
        { value: null, text: this.$t("g.all") },
        { value: true, text: this.$t("g.available") },
        { value: false, text: this.$t("g.not_available") },
      ],
      filterQuery: null,
      archive: [],
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "countries");
      return this.$store.state.userData.sub_Permission;
    },
  },
  beforeMount() {
    this.getCountriesData();
  },
  methods: {
    async getCountriesData() {
      try {
        this.isLoading = true;
        const response = await this.$http.get("admin/countries");
        this.tableBody = [response.data.data].flat().map((item) => ({
          id: item.id,
          name: item.name,
          lang: item.lang,
          code: item.code,
          currency: item.currency,
          flag: item.flag,
          is_available: item.is_available,
        }));
        this.archive = this.tableBody;
      } catch (error) {
        this.subPermission.index
          ? this.$helpers.makeToast(
              "danger",
              this.$t("g.send.errorBody"),
              this.$t("g.send.errorTitle")
            )
          : null;
      } finally {
        this.isLoading = false;
      }
    },
    async updateCountry(countryId, state) {
      try {
        this.overlayLoading = true;
        const formData = new FormData();
        formData.append("is_available", state ? 1 : 0);
        await this.$http.post(
          `/admin/countries/${countryId}?_method=put`,
          formData
        );
        this.$helpers.makeToast(
          "success",
          this.$t("g.send.successBody"),
          this.$t("g.send.successTitle")
        );
      } catch (error) {
        this.$helpers.makeToast(
          "danger",
          this.$t("g.send.errorBody"),
          this.$t("g.send.errorTitle")
        );
        this.getCountriesData();
      } finally {
        this.overlayLoading = false;
      }
    },
  },
  watch: {
    filterQuery(newValue, oldValue) {
      if (newValue !== null) {
        const newData = [];
        this.archive.map((country) => {
          if (country.is_available === newValue) {
            newData.push(country);
          }
        });
        this.tableBody = newData;
      } else {
        this.tableBody = this.archive;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.options {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
</style>
